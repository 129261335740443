<template>
	<div class="content-wrap">
		<nav-bar title="教练员学习入口" id="navbar"></nav-bar>
		<div class="wrap-bus">
			<img src="../../assets/yunnan/app02.png" alt="" @click="gotoStudy('APP02')">
			<img src="../../assets/yunnan/app04.png" alt="" @click="gotoStudy('APP04')">
		</div>
	</div>
</template>

<script>
	import {NavBar} from "vant";
	export default {
		name: "yunnan-coach",
		components: { NavBar },
		methods: {
			gotoStudy(appNo) {
				window.location.href = '/index.html#/login?appNo=' + appNo;
			}
		}
	}
</script>

<style lang="less" scoped>
	.content-wrap{
		width: 100%;
		height: 100vh;
		background: url('../../assets/shandong/shandongThree_bj.png') no-repeat;
		background-size: 100% 100%;
		position: relative;
		.wrap-bus{
			:nth-child(1){
				position: absolute;
				top: 125px;
				left: 0;
				width: 100%;
			}
			:nth-child(2){
				position: absolute;
				top: 350px;
				left: 0;
				width: 100%;
			}
		}
	}
</style>
